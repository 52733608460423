import React from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button';

export default function Dropdown({ label, links }) {
  return (
    <Menu className="dropdown">
      <MenuButton className="dropdown_button small whitespace-no-wrap">
        {label}
        <i aria-hidden className="ml-2 fas fa-chevron-down" />
      </MenuButton>
      <MenuList className="dropdown_list">
        {links.map(link => (
          <MenuLink
            key={link.url}
            href={link.url}
            data-method={link.method || 'GET'}
            className="dropdown_link"
            onClick={() =>
              (window.location.href = link.url)
            } /* workaround for dropdown to work in dashboard */
          >
            {link.title}
          </MenuLink>
        ))}
      </MenuList>
    </Menu>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      method: PropTypes.string
    })
  )
};
