import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GetDataStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profiles: []
    };
  }

  displayRerunMessage () {
    let result = '';

    if (this.props.numberOfReruns == 1) {
      result = '(You currently have <b>one</b> report you can rerun for free.)'
    } else if (this.props.numberOfReruns > 1) {
      result = `(You currently have <b>${this.props.numberOfReruns}</b> reports you can rerun for free.)`
    }

    return { __html: result };
  }

  componentWillUpdate(nextProps) {
    const { step, nextStep } = this.props;

    const isNextStep = nextProps.currentStep === step;

    if (isNextStep) {
      //skip this step by now
      this.props.nextStep();
    }
  }

  render() {
    const {
      nextStep,
      goToStep,
      title,
      genotypeTemplateId,
      freeRerun,
      rerunReportId
    } = this.props;

    return (
      <div>
        <div className="flex flex-wrap justify-center connect-upload p2">
          <div className="col-12 center pl1">
            <div className="mb2">
              <h3 className="m0">
                File Upload
              </h3>
              <p className="small">
                This should be a file ending in .txt or .csv that contains raw SNP data generated from taking a consumer genetic test offered by a company like <strong>23andMe</strong> or <strong>Ancestry DNA</strong>.
              </p>
            </div>
            <button
              type="button"
              onClick={() => nextStep()}
              className="btn btn-pill btn-secondary"
            >
              SELECT A FILE
            </button>
          </div>
        </div>
        {this.props.isSignedIn && (
          <div
            style={{ borderTop: '1px solid #a9c0d0' }}
            className="center py2"
          >
            <small>
              <a target="_blank" href="/genetic_dashboard">
                View existing reports
              </a>
            </small>
            <br />
            <small>
              <em dangerouslySetInnerHTML={this.displayRerunMessage()}></em>
            </small>
          </div>
        )}
      </div>
    );
  }
}

GetDataStep.propTypes = {
  updateFormValues: PropTypes.func.isRequired,
  genotypeTemplateId: PropTypes.number,
  isSignedIn: PropTypes.bool.isRequired,
  freeRerun: PropTypes.bool.isRequired,
  rerunReportId: PropTypes.number,
  numberOfReruns: PropTypes.number
};

GetDataStep.defaultProps = {
  genotypeTemplateId: null,
  rerunReportId: null,
  numberOfReruns: 0
};

export default GetDataStep;
