import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { isRequired, isEmail } from '../../../../validations';

import VerifyEmailInput from 'components/VerifyEmailInput';

const SkipRegistration = ({ buttonCopy, onSubmit }) => {
  const [email, setEmail]                       = useState('');
  const [isValidEmail, setIsValidEmail]         = useState(false);
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [error, setError]                       = useState({});

  const handleSubmit = async evt => {
    evt.preventDefault();

    if(isVerifyingEmail) {
      return;
    }

    const { data } = await axios.get(`/api/v1/users/${btoa(email)}`);

    if (data.error) {
      setError(data.error);
    } else {
      onSubmit(email, data.subscriber);
    }
  }

  const handleChange = ({ target }, isValid) => {
    const { value } = target;

    setEmail(value);
    setIsValidEmail(isValid);
  };

  return (
    <form action="/api/v1/users"
          method="get"
          onSubmit={ handleSubmit }
          className="h-full">

      <div className="flex flex-column h-full">
        <h4>Skip Registration...</h4>

        <div className="flex">
          <VerifyEmailInput name="email"
                            placeholder="Enter Email"
                            onChange={ handleChange }
                            onChangeIsVerifying={ setIsVerifyingEmail }
                            required={ true } />
        </div>

        {
          error.email
          && (
            <div className="error">
              {error.email}
            </div>
          )
        }

        <p className="mt2 lower-text italic flex-grow">
          Get the PDF by itself as a quick download. Fewer features, but less fuss.
        </p>

        <div  className="flex-grow position-relative mb2"
              style={{ height: '3rem' }}>

          <button className="btn btn-pill btn-block btn-secondary position-absolute"
                  style={{ bottom: 0 }}
                  disabled={ !isValidEmail || isVerifyingEmail }>
            {
              buttonCopy
              || <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>
            }
          </button>
        </div>
      </div>
    </form>
  );
};

SkipRegistration.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SkipRegistration;
