import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportDescription from '../ReportDescription';

class TemplateSelectionStep extends Component {
  constructor(props) {
    super(props);
  }

  onAccept() {
    const { genotypeTemplateId, currentStep, nextStep, step } = this.props;

    if (genotypeTemplateId != null && currentStep === step) {
      this.props.updateFormValues(this.props);
      nextStep();
    }
  }

  render() {
    const {
      templates,
      updateFormValues,
      nextStep,
      currentStep,
      genotypeTemplateId
    } = this.props;

    if (genotypeTemplateId != null && currentStep === 0) {
      if (window.location.pathname.indexOf(this.props.genotypeTemplate.slug) >= 0) {
        return <ReportDescription {...this.props.genotypeTemplate} minimumAmount={this.props.minimumAmount} onAccept={this.onAccept.bind(this)} />;
      } else {
        this.onAccept();
      }
    }

    const templateButtons = templates.map(({ id, name, minimumAmount }) => (
      <div key={id} className="mb2">
        <button
          type="button"
          onClick={() => {
            updateFormValues({
              genotypeTemplateId: id,
              minimumAmount
            });
            nextStep();
          }}
          className="btn btn-block btn-pill btn-primary"
        >
          {name}
          {minimumAmount > 0 && <span> - $</span>}
        </button>
      </div>
    ));

    return (
      <div className="flex flex-column template-options">
        <div className="mb2">
          <h4>Select a type of report</h4>
        </div>
        {templateButtons}
      </div>
    );
  }
}

TemplateSelectionStep.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  updateFormValues: PropTypes.func.isRequired,
  genotypeTemplateId: PropTypes.number,
  nextStep: PropTypes.func,
  currentStep: PropTypes.number
};

TemplateSelectionStep.defaultProps = {
  genotypeTemplateId: null,
  nextStep: null,
  currentStep: null
};

export default TemplateSelectionStep;
